<template>
  <div class="tab-btn-box">
    <router-link :to="notice" class="tab-btn" :class="{ on : path === notice }">공지사항</router-link>
    <a @click="checkLoginStatus(inquiry)" class="tab-btn" :class="{ on : path === inquiry }">1:1문의</a>
    <router-link :to="faq" class="tab-btn" :class="{ on : path === faq }">FAQ</router-link>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex"

export default {
  name: "csCenterTab",
  data() {
    return {
      path: this.$route.path,
      notice: '/notice',
      inquiry: '/inquiry',
      faq: '/faq',
      isOpenLoginPopup: false,
    }
  },
  computed: {
    ...mapGetters({
      isLogin: 'session/isLogin',
    })
  },
  methods: {
    ...mapActions({
      setMsgPopup: 'common/setMsgPopup',
    }),
    checkLoginStatus(path) {
      if (!this.isLogin) {
        this.loginPopup(path)
      }
      else {
        this.$router.push(path)
      }
    },
    loginPopup(path) {
      this.setMsgPopup({
        type: 'alert',
        message: this.$msg('login.need'),
        okay: 'OK',
        okayCallback: () => {
          this.setMsgPopup()
          localStorage.setItem('loginBeforePage', path)
          this.$router.push('/login')
        }
      })
    },
  }
}
</script>
